@import '../../App.module.sass';

$yellow: #FFFB00;
$black: #404000;
$grey: #555;

.container {
	height: 100%;
	width: 100vw;
	background: $grey;
	color: #FFF;
	overflow: hidden;
	position: relative; }

.tape {
	background-image: repeating-linear-gradient(-45deg, $yellow, $yellow 25px, $black 25px, $black 50px);
	height: 30px;
	position: absolute;
	top: 50px;
	left: 0;
	width: 110vw;
	transform: rotate(5deg);
	&.bottomTape {
		transform: rotate(-5deg);
		bottom: 30px;
		top: auto; } }

.middle {
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	text-align: center;
	h1 {
		font-family: 'Potta One', sans-serif;
		text-transform: uppercase;
		font-size: 32px;
		animation: bobAbout 2s infinite linear; }
	img {
		max-height: 50vh;
		max-width: 50vw; }
	span {
		display: block;
		margin-top: 15px {
    font-size: 20px; } } }


@keyframes bobAbout {
	0%, 100% {
		transform: translateX(10px); }
	50% {
		transform: translateX(-10px); } }



// ---------- *********** ------------ //
@media (min-width: 1200px) {
	.middle {
		h1 {
			font-size: 45px; }
		span {
			font-size: 30px; } } }
